<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useHttp from '@/comp-functions/useHttp'
import printInvoiceadjustment from '@/components/print-invoiceadjustment'
import router from "@/router"
import {formatNumber} from '@/utils/formatter'
import Swal from "sweetalert2"

import { ref , onMounted} from '@vue/composition-api'

const DETAIL_ADJ = { 
  indDescription: '', 
  indTotalcharge: '',
  }

export default {
  page: {
    title: "Input Invoice",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Input Invoice",
      items: [],
    };
  },
  props:{
      orderid: String
  },
  setup(props){
    const { $post } = useHttp()
    const { $get } = useHttp()

    const form = ref({
      invOrderid: props.orderid,
      invGtotalcharge : 0,
      invCollies : 0,
      invWeight :  0,
      invVatcharge : 0,
      details: []
    })
     
     const formdisplay = ref({
      orderNumber: '',
      ord_manifestno:'',
      invoiceNo: '',
      ordGtotalcharge: '',
      invoiceTotalamount: '',
      invTotalcharge : 0,
      balanceAmountdisplay: '',
      balanceAmount:0,
      balancegtamount:0,
      vnd_name: '',
      cus_name: '',
      ord_weight: ''
    })
     const getData = async () => {
      const {data} = await $get({ url: 'master/orderv2/balanceofbill/' + form.value.invOrderid})
      formdisplay.value.orderNumber = data.ord_no
      formdisplay.value.vnd_name = data.vnd_name
      formdisplay.value.cus_name = data.cus_name
      formdisplay.value.ord_weight = data.ord_weight
      formdisplay.value.ord_manifestno = data.ord_manifestno
      formdisplay.value.ordGtotalcharge = formatNumber(data.ord_gtotalcharge)
      formdisplay.value.invoiceTotalamount = formatNumber(data.invoice_totalamount)
      formdisplay.value.balancegtamount = data.ord_gtotalcharge - data.invoice_totalamount
      formdisplay.value.balanceAmount = (formdisplay.value.balancegtamount*100) / (100+(0.01*100))
      formdisplay.value.balanceAmountdisplay = formatNumber(formdisplay.value.balanceAmount)
      form.value.invOrderid = data.ord_id
      form.value.invGtotalcharge = 0
      form.value.invCollies = data.ord_collies - data.invoice_collies
      form.value.invWeight = data.ord_weight - data.invoice_totalweight
      form.value.invVatcharge = 0
    }

    const formDetailAdj = ref({...DETAIL_ADJ})

    const postForm = async () => {
      console.log(parseInt(formdisplay.value.balancegtamount)-parseInt(form.value.invGtotalcharge))
      if((parseInt(formdisplay.value.balancegtamount)-parseInt(form.value.invGtotalcharge)) !== 0){
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `Total balance must be 0`,
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        return false
      }
      const {data} = await $post({
        url: 'master/invoice',
        data: form.value
      })
      router.push({name: 'apps-inv-created', params: {inv_id : data.inv_id}})

  }
    const addRow = () => {
      if(formDetailAdj.value.indDescription.length === 0){
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `Description field is required`,
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        return false
      }
      if(formDetailAdj.value.indTotalcharge.length === 0 || formDetailAdj.value.indTotalcharge === 0){
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `Amount field is required`,
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        return false
      }
      form.value.details.push({
        ...formDetailAdj.value,
        //description: formDetailAdj.value.indDescription,
        //totalAmount : formDetailAdj.value.indAmount
        
      })
      formDetailAdj.value = {...DETAIL_ADJ}
      detailsLength.value = form.value.details.length +1
      sumAmount()
    }
    const sumAmount = () =>{
      formdisplay.value.invTotalcharge = form.value.details.reduce((accumulator, {indTotalcharge}) => (accumulator + parseInt(indTotalcharge)), 0)
      form.value.invVatcharge = Math.floor(formdisplay.value.invTotalcharge * 0.01)
      form.value.invGtotalcharge = formdisplay.value.invTotalcharge + form.value.invVatcharge
    }

    const removeRow =  (index) => {
      if (form.value.details.length <= 0) return false
      form.value.details.splice(index, 1)
      detailsLength.value = form.value.details.length +1
      sumAmount()
    }

    const detailsLength = ref(form.value.details.length+1)

onMounted(() => {
      getData()
    })

 return{
   formdisplay,
   formDetailAdj,
   form,
   formatNumber,
   addRow,
   removeRow,
   detailsLength,
   postForm,
   getData
  //  getOrder
 }
  },

  computed: {
  },
  methods: {
    createInvoiceadjustment () {
      printInvoiceadjustment(1)
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

     <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group mb-3">
                  <label>Order No.</label><br />
                  <b-form-input v-model="formdisplay.orderNumber" disabled></b-form-input>
                </div>
                  <div class="form-group mb-3">
                  <label>SMU Number</label><br />
                  <b-form-input v-model="formdisplay.ord_manifestno"  disabled></b-form-input>
                </div>
                <div class="form-group mb-3">
                  <label>Airlines</label><br />
                  <b-form-input v-model="formdisplay.vnd_name" disabled></b-form-input>
                </div>
                  <div class="form-group mb-3">
                  <label>Customer Name</label><br />
                  <b-form-input v-model="formdisplay.cus_name"  disabled></b-form-input>
                </div>
                </div>
              <div class="col-md-6">
                <div class="form-group mb-3">
                  <label>Total Chargeable Weight</label><br />
                  <b-form-input v-model="formdisplay.ord_weight" disabled></b-form-input>
                </div>
                  <div class="form-group mb-3">
                  <label>Order Grand Total Amount</label><br />
                  <b-form-input v-model="formdisplay.ordGtotalcharge"  disabled></b-form-input>
                </div>
                <div class="form-group mb-3">
                  <label>Existing Invoice Grand Total Amount</label><br />
                  <b-form-input v-model="formdisplay.invoiceTotalamount" disabled></b-form-input>
                </div>
                <div class="form-group mb-3">
                  <label>Total Balance Exclude VAT</label><br />
                  <b-form-input v-model="formdisplay.balanceAmountdisplay"  disabled></b-form-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form class="form-horizontal" role="form">
                  <div class="table-responsive" style="min-height:250px">
                    <table class="table mb-0">
                      <thead>
                        <tr>
                          <th width = "100">No.</th>
                          <th>Description<span class="text-danger">*</span></th>
                          <th width ="180">Amount<span class="text-danger">*</span></th>
                          <th>#</th>
                        </tr>
                      </thead>
                      <tbody>
                         <tr>
                          <th scope="row" class="text-center">
                            {{detailsLength}}
                          </th>
                          <td><b-form-input v-model="formDetailAdj.indDescription"></b-form-input></td>
                          <td><b-form-input v-model="formDetailAdj.indTotalcharge"></b-form-input></td>
                          <td class="text-center">
                            <b-button size="sm" variant="primary" @click="addRow()">
                              <i class="ri-add-line"></i>
                            </b-button>
                          </td>
                        </tr>
                        <tr v-for="(detailOrder,index) in form.details" :key="index">
                          <th scope="row">{{index+1}}</th>
                          <td class="text-left">{{detailOrder.indDescription}}</td>
                          <td class="text-left">{{formatNumber(detailOrder.indTotalcharge)}}</td>
                          <td class="text-center">
                            <b-button size="sm" variant="danger" @click="removeRow(index)">
                              <i class="ri-delete-bin-line"></i>
                            </b-button>
                          </td>
                        </tr>
                      </tbody>
                        <tr>
                          <td colspan="2" class="text-right">VAT</td>
                          <td class="text-right">{{formatNumber(form.invVatcharge)}}</td>
                        </tr>
                        <tr>
                          <td colspan="2" class="text-right">Grand Total</td>
                          <td class="text-right">{{formatNumber(form.invGtotalcharge)}}</td>
                        </tr>
                        <tr>
                          <td colspan="2" class="text-right">Balance Exclude VAT</td>
                          <td class="text-right">{{formatNumber(formdisplay.balanceAmount - formdisplay.invTotalcharge)}}</td>
                        </tr>
                      <tfoot>
                      </tfoot>
                    </table>
                  </div>
                </form>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
            
    <!-- end row -->
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <div class="badge badge-info font-size-17">
                  Total Balance : {{ formatNumber(formdisplay.balancegtamount-form.invGtotalcharge) }}
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <div class="button-items">
                  <b-button variant="primary" @click="postForm">
                    <i class="ri-save-line align-middle ml-2"></i>
                    Submit
                  </b-button>
                   <b-button variant="danger" :to="{ name: 'apps-manageorder-list'}">
                    <i class="ri-arrow-left-line align-middle mr-2"></i> Back
                  </b-button>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>